import {useContext, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {toJS} from "mobx";

import ErrorBlock from "../ErrorBlock";
import FileBlock from "./FileBlock";
import LoadingBlock from "../LoadingBlock";
import LinkToExternalItem from "./LinkToExternalItem";
import NormalizedFileBlock, {ViewerTypeEnum} from "./NormalizedFileBlock";
import NormalizedFolderBlock from "./NormalizedFolderBlock";
import {StoreContext} from "../../../../stores/StoreLoader";
import {getFolderIcon, getLegacyFileIcon,} from "../../TerracedGridTheme/FilesFoldersBlock/fileUtilities";
import moment from 'moment';
import {IBlockComponentProps} from "../_Block";

export interface ISharedFilesBlockProps extends IBlockComponentProps {
    blockObj: ISharedFilesBlockObj
}

function normalizeLegacySharedFileBlock(blockObj) {
    const blockToReturn = {...blockObj};
    if (!(blockToReturn.file || blockToReturn.folder)) return blockToReturn;
    if (blockToReturn.folder) {
        blockToReturn.folder.files = blockToReturn.folder.files.map((item) => {
            const time = item.modifiedTimestamp.timezone ?
                moment(item.modifiedTimestamp.date + item.modifiedTimestamp.timezone) :
                moment(item.modifiedTimestamp.date);
            return {
                ...item,
                icon: getLegacyFileIcon(item),
                modifiedTimestamp: time.toISOString(),
            }
        });
    } else {
        blockToReturn.file.icon = getLegacyFileIcon(blockToReturn.file);
    }
    return blockToReturn;
}

function normalizeWatsonSharedFileBlock(blockObj, sourceData, isAdmin) {
    const blockToReturn = {...blockObj};

    const isFileVsFolder = sourceData.type.split('.')[1];
    switch (isFileVsFolder) {
        case 'file':
            blockToReturn.file = sourceData.items[0];
            blockToReturn.file.icon = getLegacyFileIcon(blockToReturn.file);
            blockToReturn.fileBlockType = "normalizedFile";
            break;
        case 'folder':
            if (!blockToReturn.folder) blockToReturn.folder = {};
            blockToReturn.fileBlockType = "normalizedFolder";
            blockToReturn.folder.viewLink = sourceData.source_url;
            blockToReturn.folder.icon = getFolderIcon(sourceData.type);
            blockToReturn.folder.type = sourceData.type;
            blockToReturn.folder.files = sourceData.items?.map((item) => {
                // the webUrl that Microsoft Graph API provides always requires authentication. To deal with this,
                // we only link to the individual file for site admins in the hope that they are also authenticated
                // with office. For everyone else, we link to the folder which does not require authentication. For
                // Google, we continue to link to the file.
                const viewLink = (!isAdmin && sourceData.type.includes("o365")) ? sourceData.source_url : item.viewLink;
                return {
                    ...item,
                    viewLink,
                    icon: getLegacyFileIcon(item),
                }
            });
            break;
    }

    return blockToReturn;
}

export function getContentItemFileViewerType(contentItem): ViewerTypeEnum {
    //viewer_type currently only exists in watson for o365.
    // if its a google drawing, make the viewerType an iframe so it is clickable.
    return contentItem.json_data?.file?.viewer_type ?
        contentItem.json_data?.file?.viewer_type :
        (contentItem.json_data?.file?.mime_type === 'application/vnd.google-apps.drawing' ? ViewerTypeEnum.IFRAME : ViewerTypeEnum.GOOGLE);
}

const SharedFilesBlock = observer((props: ISharedFilesBlockProps) => {
    const {i18nStore, userStore} = useContext(StoreContext);
    const [fileData, setFileData] = useState<ISharedFilesBlockObj | null>(null);

    useEffect(() => {
        const blockObj = toJS(props.blockObj);
        const contentSources = props.blockObj?.contentSources;
        if (!props.isBlockPreview) {
            try {
                const fallbackContentSource = contentSources?.find((source) => source.language === i18nStore.orgDefaultLocale);
                const currentContentSource = contentSources?.find((source) => source.language === i18nStore.currentOrgSupportedLocale);
                const contentSourceData = currentContentSource ? currentContentSource : fallbackContentSource;
                // if there is data, use it. Otherwise, just use blockObj stuff from PHP.
                if (contentSourceData) {
                    // this value is 'file' or 'folder'
                    setFileData(normalizeWatsonSharedFileBlock(blockObj, contentSourceData, userStore.isEditor));
                } else {
                    setFileData(normalizeLegacySharedFileBlock(blockObj));
                }
            } catch (error) {
                blockObj.fileBlockType = null;
                blockObj.errorMessage = error;
                console.error(error);
            }
        }
    }, [i18nStore.currentOrgSupportedLocale, props.blockObj.contentSources]);

    useEffect(() => {
        if (props.isBlockPreview) {
            if (props.blockObj.contentSources?.length > 0) {
                const sourceData = props.blockObj?.contentSources?.find(s => s.language === i18nStore.blockEditLanguage);
                if (sourceData) {
                    setFileData(normalizeWatsonSharedFileBlock(props.blockObj, sourceData, userStore.isEditor));
                }
            } else {
                setFileData(normalizeLegacySharedFileBlock(props.blockObj));
            }
        }
    }, [i18nStore.blockEditLanguage, props.blockObj?.blockModel?.json_data?.block?.settings?.url])

    if (fileData) {
        switch (fileData.fileBlockType) {
            case "normalizedFile":
                return <NormalizedFileBlock key={i18nStore.currentOrgSupportedLocale + fileData.id} {...props}
                                            blockObj={fileData}/>;
            case "normalizedFolder":
                return <NormalizedFolderBlock key={i18nStore.currentOrgSupportedLocale + fileData.id} {...props}
                                              blockObj={fileData}/>;
            case "file":
                return <FileBlock key={i18nStore.currentOrgSupportedLocale + fileData.id} {...props}
                                  blockObj={fileData}/>;
            case "linkToExternalItem":
                return <LinkToExternalItem {...props} blockObj={fileData}/>;
            default:
                return <ErrorBlock key={i18nStore.currentOrgSupportedLocale + fileData.id}
                                   blockObj={{errorMessage: fileData.errorMessage as string}}/>;
        }
    } else {
        return <LoadingBlock/>;
    }
})

export default SharedFilesBlock;
