import moment from 'moment';
import BlockScroller from "../BlockScroller";
import BlockHead from "../_BlockHead";
import ClickableLink from "../../../utilities/ClickableLink";
import GoogleFolder from "../../../../assets/images/file-types/google/icon_1_folder_x32.png";
import O365Folder from "../../../../assets/images/file-types/office365/folder.png";
import {observer} from "mobx-react";
import {ISharedFilesBlockProps} from "./SharedFilesBlock";
import ImageComponent from "../../../utilities/ImageComponent";

const NormalizedFolderBlock = observer((props: ISharedFilesBlockProps) => {
    const folder = props.blockObj.folder as NonNullable<ISharedFilesBlockObj["folder"]>;

    const type = folder.type?.split('.')[0];
    let headerIcon;
    switch (type) {
        case "o365":
            headerIcon =  <ImageComponent src={O365Folder} aria-hidden={"true"} alt={"Office 365 Folder Icon"}
                               style={{display: "inline-block", marginRight: "0.3em", height: "1.6em", width: "1.6em", verticalAlign: "top"}}
            />;
            break;
        case "google_drive":
        default:
            headerIcon = <ImageComponent src={GoogleFolder} aria-hidden={"true"} alt={"Google Drive Folder Icon"}
                              style={{display: "inline-block", marginRight: "0.3em", height: "1.6em", width: "1.6em", verticalAlign: "top"}}
            />;
            break;
    }

    return <BlockScroller blockRef={props.blockRef}
                          blockObj={props.blockObj}
                          htmlId={props.htmlId}
                          setShowExpandButton={props.setShowExpandButton}
                          outsideGrid={props.outsideGrid}>
        <BlockHead blockType="sharedfiles" blockId={props.blockObj.id}>
            {headerIcon && <ClickableLink href={folder.viewLink} style={{position: "static"}} title={`Open Folder In New Window`}>
                {headerIcon}
                <span>{props.blockObj.blockModel.title ? props.blockObj.blockModel.title : "Shared Files"}</span>
            </ClickableLink>}
        </BlockHead>
        <div className="sb-blockContent sb-files-embedded sb-organization-color-element-font clearfix">
            {folder.files?.map(file => {
                return <a key={file.id} href={file.viewLink} target="_blank" rel="nofollow noopener noreferrer">
                    <div className="clearfix sb-file-list-entry">
                        {file.icon &&
                            <ImageComponent src={file.icon} alt={`View ${file.title}`} aria-hidden="true"
                                 style={{float: "left", height: "20px",  width: "20px"}}/>}
                        <div style={{wordWrap: "break-word", marginLeft: "30px"}}>
                            <span style={{color: "#333"}}>{file.title}</span>
                            <br/>
                        </div>
                    </div>
                </a>
            })}

            {!!(folder.viewMoreFiles && folder.viewMoreFiles > 0) && <div className={"text-center"}>
                <ClickableLink href={folder.viewLink} title={folder.title} classname={"sb-organization-color-element-bg"}>
                    <span>View {folder.viewMoreFiles} More Files</span>
                </ClickableLink>
            </div>}
        </div>
    </BlockScroller>
})

export default NormalizedFolderBlock;
