import ClickableLink from "../../../utilities/ClickableLink";
import BlockHead from "../_BlockHead";
import {observer} from "mobx-react";
import {ISharedFilesBlockProps} from "./SharedFilesBlock";
import ImageComponent from "../../../utilities/ImageComponent";

const LinkToExternalItem = observer((props: ISharedFilesBlockProps) => {
    const {icon, viewLink, type} = props.blockObj.fileOrFolder as NonNullable<ISharedFilesBlockObj["fileOrFolder"]>;
    const title = props.blockObj.blockModel.title ? props.blockObj.blockModel.title : "Shared Files";

    return <div>
        <BlockHead className="sb-blockHead sb-block-head-border sb-organization-color-block-bg"
                   blockType={props.blockObj.blockType} editableTitle={title} blockId={props.blockObj.id}>
            <ClickableLink href={viewLink}
                           style={{position: "static"}} title={`View the ${type}`}>
                <ImageComponent src={icon}
                     alt={title}
                     aria-hidden="true"
                     style={{display: "inline-block", marginRight: "0.3em", height: "1.6em",  width: "1.6em", verticalAlign: "top"}}/>
            </ClickableLink>
        </BlockHead>
        <div className="sb-blockContent sb-organization-color-element-font clearfix"
             style={{padding: 0, textAlign: "center"}}>
            <ClickableLink href={viewLink} className="btn sb-organization-color-element-bg"
                           style={{marginTop: "30%"}}>Click to view the {type}</ClickableLink>
        </div>
    </div>
})

export default LinkToExternalItem;
